import numeral from "numeral"
import { CompanyTableItem } from "~/utils/db/companySignals"

export function getGrowthOperator(value: number): string {
  if (value <= 0) {
    return ""
  }

  return "+"
}

export function hasValue(value: number | null | undefined): boolean {
  return value !== null && Number(value) > 0
}

export const getPercentageDifference = (
  base: number | null,
  growth: number | null
): string => {
  if (base === null || growth === null) return "N/A"

  const absoluteB = base - growth

  const difference = ((base - absoluteB) / absoluteB) * 100

  return numeral(difference).format("0.[00]") + "%"
}

export function formatRange(s: string): string {
  if (!s) {
    return ""
  }

  const parts = s.split("-")

  if (parts.length > 1) {
    if (parts[0] == parts[1]) {
      return `>${parts[0]}`
    } else {
      return `${parts[0]} - ${parts[1]}`
    }
  }

  return parts[0]
}

export interface CompanyDetailTabProps {
  signal: CompanyTableItem
  onChangeTab?: (tabIndex: number) => void
}
