export const G2_LOGO_URL =
  "https://specter-company-logo.s3.amazonaws.com/g2.com.png"

export const G2_COLOR = "rgba(247, 113, 113)"

export const TRUSTPILOT_LOGO_URL = "/trustpilot_logo.svg"
export const TRUSTPILOT_URL = "https://www.trustpilot.com"

export const TRUSTPILOT_COLOR = "#08DA8D"

export const getTrustPilotURL = (domain: string) =>
  `${TRUSTPILOT_URL}/review/${domain}`
