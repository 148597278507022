import {
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react"

interface Props {
  isOpen: boolean
  onClose: () => void
  screenshot: string
}

export const ScreenshotModal = ({
  isOpen,
  onClose,
  screenshot,
}: Props): JSX.Element => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Image src={screenshot} alt="Screenshot" />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
