/* eslint-disable prettier/prettier */
export const clipPathFrame = ({
  borderWidth = 1,
  borderRadius = 10,
  roundness = 10,
} = {}) => {
  const points = []

  for (let i = 0; i <= Math.PI / 2; i += Math.PI / 2 / roundness) {
    points.push({
      x: Math.cos(i),
      y: Math.sin(i),
    })
  }

  const outerRadius = borderRadius
  const innerRadius = borderRadius - borderWidth

  const pointsInReverse = points.slice().reverse()

  const polygon = `
        polygon(
          ${borderWidth}px 100%,

          ${points
            .map(
              (point) =>
                `${borderWidth + innerRadius - innerRadius * point.x}px ${
                  borderWidth + innerRadius - innerRadius * point.y
                }px`
            )
            .join(",\n")},
          ${pointsInReverse
            .map(
              (point) =>
                `calc(100% - ${
                  borderWidth + innerRadius - innerRadius * point.x
                }px) ${borderWidth + innerRadius - innerRadius * point.y}px`
            )
            .join(",\n")},
          ${points
            .map(
              (point) =>
                `calc(100% - ${
                  borderWidth + innerRadius - innerRadius * point.x
                }px) calc(100% - ${
                  borderWidth + innerRadius - innerRadius * point.y
                }px)`
            )
            .join(",\n")},
          ${pointsInReverse
            .map(
              (point) =>
                `${
                  borderWidth + innerRadius - innerRadius * point.x
                }px calc(100% - ${
                  borderWidth + innerRadius - innerRadius * point.y
                }px)`
            )
            .join(",\n")},

          ${borderWidth}px 100%,

          ${pointsInReverse
            .map(
              (point) =>
                `calc(100% - ${
                  outerRadius - outerRadius * point.x
                }px) calc(100% - ${outerRadius - outerRadius * point.y}px)`
            )
            .join(",\n")},
          ${points
            .map(
              (point) =>
                `calc(100% - ${outerRadius - outerRadius * point.x}px) ${
                  outerRadius - outerRadius * point.y
                }px`
            )
            .join(",\n")},
          ${pointsInReverse
            .map(
              (point) =>
                `${outerRadius - outerRadius * point.x}px ${
                  outerRadius - outerRadius * point.y
                }px`
            )
            .join(",\n")},
          ${points
            .map(
              (point) =>
                `${outerRadius - outerRadius * point.x}px calc(100% - ${
                  outerRadius - outerRadius * point.y
                }px)`
            )
            .join(",\n")}
        )
      `

  return polygon
}
