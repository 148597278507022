import { Heading, HeadingProps } from "@chakra-ui/react"
import { ReactNode } from "react"

type Props = Omit<HeadingProps, "title"> & {
  title: ReactNode
}

export const TabSubsectionTitle = ({ title, ...props }: Props) => (
  <Heading
    as="h2"
    size="sm"
    mb={4}
    w="fit-content"
    sx={{
      breakAfter: "avoid",
    }}
    display="inline-flex"
    gap={2}
    alignItems="center"
    {...props}
  >
    {title}
  </Heading>
)
