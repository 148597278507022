import en from "javascript-time-ago/locale/en"
import TimeAgo from "javascript-time-ago"

export function make_timeago() {
  TimeAgo.setDefaultLocale(en.locale)
  TimeAgo.addLocale(en)
  return new TimeAgo("en")
}

export const timeago = make_timeago()
