export const getGrowthColor = (growth: number, inverted?: boolean) => {
  const growthValue = inverted ? -growth : growth

  if (growthValue > 0) {
    return "green"
  } else if (growthValue < 0) {
    return "red"
  } else {
    return "black"
  }
}
