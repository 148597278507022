import { SimpleGrid, Text } from "@chakra-ui/react"
import { Card } from "~/components/Card"
import { DetailValueCard } from "../DetailValueCard"
import { CompanyDetailTabProps } from "../utils"

export const Patents = ({ signal }: CompanyDetailTabProps): JSX.Element => {
  if (signal.patentsCount === null && signal.trademarksCount === null) {
    return (
      <Card>
        <Text fontSize="sm" fontWeight="semibold">
          We don’t have any patents or trademark data for this company at this
          time.
        </Text>
      </Card>
    )
  }

  return (
    <SimpleGrid templateColumns="1fr 1fr" gap={4}>
      <DetailValueCard label="Number of Patents" value={signal.patentsCount} />
      <DetailValueCard
        label="Number of Trademarks"
        value={signal.trademarksCount}
      />
    </SimpleGrid>
  )
}
